@use '@angular/material' as mat;
@use 'sass:map';
@use 'colors';
@use 'palettes';

// Include the core styles ONCE globally.
@include mat.elevation-classes();
@include mat.app-background();

// Define default palettes
$_default-warn-color: map.get($map: colors.$theme-color-defaults, $key: 'warn');
$_warn-palette-map: palettes.generate($_default-warn-color);
$_default-warn-palette : mat.m2-define-palette($_warn-palette-map);
$_default-primary-palette: mat.m2-define-palette(mat.$m2-grey-palette, 900);

// Define the default accent palette using the 'blue' color
$_default-accent-color: map.get($map: colors.$accent-colors, $key: 'blue');
$_default-accent-palette-map: palettes.generate($_default-accent-color);
$_default-accent-palette: mat.m2-define-palette($_default-accent-palette-map);

// Define custom typography level (used across all themes)
$_custom-body-1-level: mat.m2-define-typography-level(
  $font-size: 14px,
  $line-height: 1.5,
  $font-weight: 400
);

// Define the typography config (used across all themes)
$_typography-config: mat.m2-define-typography-config(
  $body-1: $_custom-body-1-level
);

// Define a default theme configuration including color, typography and density
$_default-theme: mat.m2-define-light-theme((
    color: (
        primary: $_default-primary-palette,
        accent: $_default-accent-palette, // Default accent
        warn: $_default-warn-palette,
    ),
    typography: $_typography-config,
    density: -1
));

// Include base, typography, density, and default color styles ONCE globally.
// This prevents duplicating non-color styles for each theme variation.
@include mat.all-component-themes($_default-theme);

// Mixin to generate only the color-specific overrides for a theme variation
@mixin _createThemeColorOverrides($color) {
    // Get the specific accent color and generate its palette
    $accent-color: map.get($map: colors.$accent-colors, $key: $color);
    $accent-palette-map: palettes.generate($accent-color);
    $accent-palette: mat.m2-define-palette($accent-palette-map);

    // Define the full theme structure for this variation (needed for context)
    $theme: mat.m2-define-light-theme((
        color: (
            primary: $_default-primary-palette, // Primary is shared
            accent: $accent-palette,         // Accent is theme-specific
            warn: $_default-warn-palette,      // Warn is shared
        ),
        typography: $_typography-config, // Typography is shared
        density: -1                      // Density is shared
    ));

    // Apply the theme-specific color styles within its class
    .theme-#{$color} {
        // Include ONLY the color styles for this theme variation.
        @include mat.all-component-colors($theme);

        /* CSS Variables scoped to the theme class for runtime use */
        --accent-color: #{$accent-color};
        --accent-color-muted: #{colors.createMutedColor($accent-color)};
        --accent-color-medium: #{rgba($accent-color, .75)};
    }
}

// Mixin to generate all theme color overrides by looping through accent colors
@mixin createThemes() {
    @each $key, $color in colors.$accent-colors {
        @include _createThemeColorOverrides($key);
    }
}
